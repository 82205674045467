import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

import { Route } from 'vue-router';
import { appConfigModule } from '@/store/modules/appConfig';
NProgress.configure({ showSpinner: false });

async function loadmodules2() {
    const routerModule = await import('@/router');
    const router = routerModule.default;
    router.beforeEach(async(to: Route, _: Route, next: any) => {
        next();
        // 启动进度条
        NProgress.start();
        NProgress.done();
    });

    router.afterEach((to) => {
        // 设置页面标题
        if (to.meta.title) {
            document.title = to.meta.title + '-' + appConfigModule.appConfig.vueShopName;
        }
    });
}
async function readModules() {
    await loadmodules2();
}
readModules();
